import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../Layouts/MainLayout";
import axios from "axios";
import { useSelector } from "react-redux";
import ChartCard from "../../Dashboard/ChartCard";
import DashboardCard from "../../Dashboard/DashboardCard";
import { FaUserCircle } from "react-icons/fa";
import Breadcrumb from "../../Breadcrumb";

function UsersPerformance() {
  const { id } = useParams(); // Extract the user ID from the URL
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const handleDarkModeChange = (e) => {
      setIsDarkMode(e.matches);
    };

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleDarkModeChange);

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleDarkModeChange);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${BASE_URL}/api/dashboard/performance-overview/`,
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { id }, // Pass the user ID as a query parameter
          }
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          error.response?.data?.error || "Failed to load user performance data."
        );
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    } else {
      setError("No user ID provided in the URL.");
      setLoading(false);
    }
  }, [id, token, BASE_URL]);

  // Line chart options for daily performance
  const lineOptions = {
    chart: {
      type: "line",
      height: 430,
    },
    title: {
      text: "Daily Performance",
      align: "left",
    },
    yAxis: {
      title: {
        text: "Number of Tasks",
      },
    },
    xAxis: {
      categories: data.daily_performance?.attempted?.map(
        (item) => item.created_at__date
      ),
    },
    series: [
      {
        name: "Attempted Tasks",
        data: data.daily_performance?.attempted?.map((item) => item.count) || [],
      },
      {
        name: "Reviewed Tasks",
        data: data.daily_performance?.reviewed?.map((item) => item.count) || [],
      },
    ],
  };

  // Donut chart options for overall performance
  const donutOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Overall Performance",
      align: "left",
    },
    series: [
      {
        name: "Tasks",
        data: [
          ["Total Attempted", data.overall_performance?.total_tasks_attempted || 0],
          ["Accepted", data.overall_performance?.total_tasks_accepted || 0],
          ["Rejected", data.overall_performance?.total_tasks_rejected || 0],
        ],
      },
    ],
  };

  return (
    <MainLayout>
      <div className="p-4 md:ml-64 pt-10 h-full dark:bg-gray-900">
        <div className="max-w-7xl mx-auto sm:px-6 lg:pt-10 ml-1 mr-1">
          <div className="mb-8">
            <section className="body-font">
              <div className="container pt-6">
                <Breadcrumb
                  items={[
                    { label: "Users", path: "/users" },
                    { label: "User Performance" },
                  ]}
                />
              </div>
            </section>
          </div>
        </div>
        <div className="mx-auto sm:px-6 mt-7 lg:pb-10">
          {loading ? (
            <div className="flex justify-center items-center">
              <p className="text-gray-500">Loading...</p>
            </div>
          ) : error ? (
            <div className="flex justify-center items-center">
              <p className="text-red-500">{error}</p>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <div className="grid gap-5 grid-cols-1">
                  <div className="bg-white overflow-hidden shadow dark:bg-gray-800 border border-dashed border-gray-300 dark:border-gray-600">
                    <div className="px-4 py-2 sm:p-6">
                      <dl className="flex flex-col gap-2">
                        <dd className="leading-5 flex gap-3 font-medium text-gray-500 truncate dark:text-gray-400">
                          <div>
                            <FaUserCircle className="w-10 text-3xl" />
                          </div>
                          <div className="text-xl mt-1">
                            User ID: {data.user_data?.id}
                          </div>
                        </dd>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                          Name:{" "}
                          {`${data.user_data?.first_name} ${data.user_data?.last_name}`}
                        </dt>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                          Email: {data.user_data?.email}
                        </dt>
                        <dt className="text-sm leading-5 font-medium text-gray-500 truncate dark:text-gray-400">
                          Role: {data.user_data?.role}
                        </dt>
                      </dl>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-5 grid-cols-1">
                    <DashboardCard
                      title="Total Tasks Attempted"
                      value={data.total_tasks_attempted}
                      secondaryText={`Tasks Attempted Today: ${
                        data.total_tasks_attempted_today || 0
                      }`}
                    />
                    <DashboardCard
                      title="Total Tasks Reviewed"
                      value={data.total_tasks_reviewed}
                      secondaryText={`Tasks Reviewed Today: ${
                        data.total_tasks_reviewed_today || 0
                      }`}
                    />
                  </div>
                </div>
                <div>
                  <ChartCard title="Overall Performance" options={donutOptions} />
                </div>
              </div>
              <div className="mt-6">
                <ChartCard title="Daily Performance" options={lineOptions} />
              </div>
            </>
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default UsersPerformance;
